import BlackbirdHandler from '../../common/lib/blackbirdHandler'

export default class BNPL {
  constructor(publicKey, embeddedContainer, businessId) {
    this.publicKey = publicKey
    this.bnplContainer = embeddedContainer
    this.businessId = businessId
  }

  submit = (extraData = {}) => {
    const paymentId = extraData.id
    const idempotencyKey = extraData.idempotencyKey

    const body = { paymentMethodDetails: { type: 'buy_now_pay_later' } }

    const blackbirdHandler = new BlackbirdHandler(this.publicKey)
    return blackbirdHandler.completePaymentAndProcessNextAction({
      paymentId,
      body,
      merchantId: this.businessId,
      idempotencyKey,
      embeddedContainer: this.bnplContainer
    })
  }
}
