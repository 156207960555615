export default class CheckoutConfiguration {
  constructor(
    public publicKey: string,
    public amountInCents?: number /** @deprecated please use amount in future **/,
    public amount?: number,
    public save?: boolean,
    public currency?: string,
    public id?: string,
    public name?: string,
    public description?: string,
    public image?: string,
    public paymentMethodDetails?: PaymentMethodDetails,
    public buttonText?: string,
    public accountId?: string,
    public customer?: string,
    public displayMethod: DisplayMethod = DisplayMethod.Auto,
    public showSaveCardCheckbox: boolean = false,
    public showAmount: boolean = true,
    public businessId?: string,
    /** string|HTMLElement element used to mount our pay button, if non is provided it will be mounted over the script tag element*/
    public mountElem?: string | HTMLElement,
    public callback?: (token: any) => void,
    public onCancel?: () => void,
    public onClose?: () => void,
    public onNextActionBegun?: () => void,
    public layout?: string,
    public metadata?: Map<string, any>,
    public eftContainer?: string | HTMLElement,
    public mode: Mode = Mode.payment,
    public nextActionContainer?: string | HTMLElement,
    public processingMode?: string,
    public shouldAllowCryptogram3ds?: boolean
  ) {}
}

export class PaymentMethodDetails {
  constructor(public save?: boolean, public usable?: string) {}
}

export enum DisplayMethod {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}

export enum Mode {
  payment = 'payment',
  subscription = 'subscription',
}
